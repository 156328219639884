





















import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import ClientModel from '@/models/crm/client.model';
import CrmActivityCalendar from '@/components/crm/activity-calendar.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    CrmActivityCalendar,
  },
})
export default class CrmCalendar extends Vue {
  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  get viewTitle(): string {
    return this.$t('crm.view.calendar.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }
}
